import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import { Container as ContainerBase } from "./misc/Layouts.jsx"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HeaderContainer = tw.div`w-full flex flex-col items-center  py-16`
const Title = tw(SectionHeading)`w-full`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 `
const Container = tw(
  ContainerBase
)`bg-gray-900 text-gray-100 -mx-8 -mb-8 h-9/12`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(
  Column
)`md:w-6/12 mt-16 flex-shrink-0 h-80 md:h-auto relative`
const FeatureDescription = tw.p`mt-4 mx-8 lg:mx-0 text-center md:text-left text-lg md:text-lg lg:text-xl font-medium leading-relaxed `
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12  md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])
const TextContent = tw.div` text-center md:text-left`
const Heading = tw(
  SectionHeading
)` font-black text-left text-4xl 2xl:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 mb-6 mx-8 lg:mx-0 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed `
const ActionsContainer = tw.div`flex flex-col lg:flex-row `
const Actions = tw(
  Link
)`text-center text-base md:text-base rounded-full px-8 py-3 mx-2 mb-8  font-bold  bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

export default ({
  title = <>Rossoが選ばれる理由</>,
  heading = (
    <>
      『 AI × AWS 』で
      <br />
      新しい価値を提供｡
    </>
  ),
  features = (
    <>
      日本ディープラーニング協会(JDLA)正会員
      <br />
      (国内33社 ※2022年2⽉現在)
      <br />
      <br />
      APNアドバンスド
      <br />
      コンサルティングパートナー認証
    </>
  ),
  description = (
    <>
      日本ディープラーニング協会(JDLA)正会員であり、AWSアドバンスドコンサルティングパートナーでもある強みを生かし、新しい価値を提供します。
      <br />
      提案からサービスローンチまでのワンストップ開発、AI＋インフラ＋アプリのトータル開発、一部自動化などのスポット開発まで、お客様のニーズに寄り添います。
    </>
  ),
  textOnLeft = false,
}) => {
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <FeatureDescription>{features}</FeatureDescription>
            <Description>{description}</Description>
            <ActionsContainer>
              <Actions to="/contact/">資料請求、お問い合わせはこちら</Actions>
              <Actions to="/consultai/">AI無料相談会実施中！</Actions>
              <Actions to="/consultcloud/">AWS無料相談会実施中！</Actions>
            </ActionsContainer>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <StaticImage src="../images/aws-jdla.png" />
        </ImageColumn>
      </TwoColumn>
    </Container>
  )
}
